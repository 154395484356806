.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    color: #3e4ee1;
}

a, a:hover, a:focus {
    text-decoration: none;
    transition: 0.5s;
    outline: none;
}

/* ------------ */

.upload-card {
    /* box-shadow: 1px 2px 8px rgb(0 0 0 / 16%); */
    border-radius: 4px;
    overflow: hidden;
    margin-bottom: 25px;
    background-color: #fff;
    border: 1px solid lightgray;
}
.card-body {
    padding-top: 12px;
}
.btn-primary {
    border-color: #5a8dee !important;
    background-color: #5a8dee !important;
    color: #fff;
}
.form-submit {
    padding: 13px 30px;
    font-size: 15px;
    letter-spacing: 0.3px;
    font-weight: 400;
    box-shadow: none;
    border: none;
    border-radius: 4px;
}
.kb-data-box {
    width: 100%;
    padding-bottom: 10px;
    flex: 1 1;
}
.kb-modal-data-title {
    margin-bottom: 10px;
}
.kb-data-title h6 {
    margin: 0;
    font-size: 12px;
    font-weight: 600;
    color: #3e4ee1;
}
.kb-file-upload {
    margin-bottom: 20px;
}
.file-upload-box {
    border: 1px dashed #b6bed1;
    background-color: #f0f2f7;
    border-radius: 4px;
    min-height: 50px;
    position: relative;
    overflow: hidden;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #8194aa;
    font-weight: 400;
    font-size: 10px;
}
.file-upload-box .file-upload-input {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
}
.file-link{
    color: #475f7b;
    text-decoration: underline;
    margin-left: 3px;
}
.file-upload-box .file-link:hover{
    text-decoration: none;
}
.file-atc-box {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}
.file-image {
    width: 50px;
    height: 60px;
    background-size: cover;
    border-radius: 5px;
    margin-right: 15px;
    background-color: #eaecf1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    color: #475f7b;
    padding: 3px;
}
.file-image img{
    max-width: 100%;
    max-height: 100%;
    border-radius: 4px;
}
.file-detail {
    flex: 1;
    width: calc(100% - 210px);
}
.file-detail h6 {
    word-break: break-all;
    font-size: 10px;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 8px;
    margin-top: 0px;
}
.file-detail p {
    font-size: 9px;
    color: #8194aa;
    line-height: 0;
    font-weight: 400;
    margin-bottom: 8px;
}
.file-actions {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    align-items: center;
}
.file-action-btn {
    font-size: 12px;
    color: #8194aa;
    line-height: 20px;
    font-weight: 400;
    margin-bottom: 0;
    padding: 0;
    background-color: transparent;
    border: none;
    text-decoration: underline;
    margin-right: 15px;
    cursor: pointer;
}
.file-action-btn:hover {
    color: #3d546f;
    text-decoration: underline;
}
.file-atc-box:last-child {
    margin-bottom: 0;
    margin-left: 40px;
}
.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
}

.spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border-left-color: #09f;
    animation: spin 1s ease infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}





.wrapper {
    width: 520px;
    max-width: 100%;
  }
  
  .progress-bar {
    height: 13px;
    width: 100%;
    background-color: #adf;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 2px 0 10px inset rgba(0, 0, 0, 0.2);
    position: relative;
}
  
  * + .progress-bar {
    margin-top: 2rem;
  }
  
  .bar {
    width: 0;
    height: 100%;
    background-color: #369;
    
    /*Lollipop background gradient*/
    background-image: linear-gradient(
      -45deg,
      rgba(255, 255, 255, .2) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, .2) 50%,
      rgba(255, 255, 255, .2) 75%,
      transparent 75%,
      transparent
    );
   
    background-size: 30px 30px;
    animation: move 2s linear infinite;
    box-shadow: 2px 0 10px inset rgba(0,0,0,0.2);
    transition: width 2s ease-out;
  }
  
  /*Lollipop background gradient animation*/
  @keyframes move {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: 30px 30px;
    }
  }
  
  .perc {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-weight: bold;
    font-size: 10px;
  }