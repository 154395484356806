.landingpage_single_year {
    padding: 20px;
    margin-right: 20px;
    text-align: center;
    text-decoration: none;
}
.landingpage_multiple_year {
    padding: 20px;
    margin-left: 20px;
    text-align: center;
    text-decoration: none;
}
.landingPage {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 140px;
}
.landing_page_content {
    display: flex;
    justify-content: center;
}
span.landingpage_single_year_text {
    color: #3e4ee1;
    font-size: 1.475rem;
    font-weight: 500;
    line-height: 1.33;
}
span.landingpage_multiple_year_text {
    color: #3e4ee1;
    font-size: 1.475rem;
    font-weight: 500;
    line-height: 1.33;
}
.landingpage_single_year_wrapper {
    border: 1px solid #939393;
    border-radius: 5px;
    margin-right: 20px;
    min-width: 265px;
    cursor: pointer;
    padding: 10px;
}
.landingpage_single_year_wrapper:hover{
    background-color: aliceblue;
}
.landingpage_multiple_year_wrapper {
    border: 1px solid #939393;
    border-radius: 5px;
    margin-left: 20px;
    min-width: 265px;
    cursor: pointer;
    padding: 10px;
}

.landingpage_multiple_year_wrapper:hover {
    background-color: aliceblue;
}
img.landingpage_single_year_icon {
    margin: auto;
    display: flex;
}
img.landingpage_multiple_year_icon {
    display: flex;
    margin: auto;
}
span.landingpage_single_year_arrow {
    color: #3e4ee1;
    font-size: 24px;
    margin-left: 10px;
}
.header_text {
    color: #3e4ee1;
    font-size: 1.45rem;
    font-weight: 300;
    text-align: center;
    line-height: 1.2em;
    margin-bottom: 70px;
}
.header_text_product_name {
    color: #3e4ee1;
    font-size: 3.45rem;
    font-weight: 800;
    text-align: center;
    line-height: 1.2em;
    margin-bottom: 70px;
}
/* LandingPage.css */

.landingPage {
    text-align: center;
  }
  
  .header_text_product_name {
    font-size: 2.5rem;
    margin-top: 20px;
  }
  
  .header_text {
    font-size: 1.2rem;
    margin-bottom: 40px;
  }
  
  .landing_page_content {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 40px;
  }
  
  .landingpage_single_year,
  .landingpage_multiple_year {
    text-decoration: none;
  }
  
  .landingpage_single_year_wrapper,
  .landingpage_multiple_year_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 20px;
    background: #fff;
    transition: transform 0.2s;
  }
  
  .landingpage_single_year_wrapper:hover,
  .landingpage_multiple_year_wrapper:hover {
    transform: scale(1.05);
  }
  
  .landingpage_single_year_icon,
  .landingpage_multiple_year_icon {
    width: 100px;
    height: 100px;
    margin-bottom: 10px;
  }
  
  .landingpage_single_year_text,
  .landingpage_multiple_year_text {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 10px;
  }
  
  .landingpage_single_year_arrow,
  .landingpage_multiple_year_arrow {
    font-size: 2rem;
    color: #333;
  }
  
  .instructions_container {
    /* max-width: 800px; */
    margin: auto;
    text-align: start;
    background: white;
    padding: 20px;
    border-radius: 10px;
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
}
.instructions_container h1 {
    text-align: center;
    color: #333;
}
.instructions_container h2 {
    color: #3e4ee1;
}
.instructions_container .instructions_containerp, ul {
    color: #555;
}
.instructions_container ul {
    list-style-type: none;
    padding: 0;
}
.instructions_container li::before {
    content: "•";
    color: #000;
    margin-right: 10px;
}
.instructions_container h1 {
    font-size: 20px;
}
.instructions_container h2 {
    font-size: 15px;
    font-weight: 400;
}
.instructions_container p{
    font-size: 15px;
}
.instruction_heading {
    font-size: 20px;
    color: #3e4ee1;
}
.instruction_heading_a  {
    margin-top: 30px;
    margin-bottom: 30px;
}
.instruction_heading {
    font-size: 15px;
    color: #3e4ee1;
    margin-bottom: 15px;
    margin-top: 90px;
}
.text-display {
    font-family: 'Courier New', Courier, monospace;
    background-color: #2b2b2b;
    color: #dcdcdc;
    padding: 20px;
    border-radius: 5px;
    position: relative;
    max-width: 600px;
    margin: auto;
    margin-left: 0px;
}
.json-display .copy-button {
    right: 112px;
}
.copy-button {
    right: 112px;
}