.json-display {
    font-family: 'Courier New', Courier, monospace;
    background-color: #2b2b2b;
    color: #dcdcdc;
    padding: 20px;
    border-radius: 5px;
    position: relative;
  }
  
  .json-content {
    padding-left: 20px;
  }
  
  .json-body {
    padding-left: 20px;
  }
  
  .json-brace {
    color: #ff79c6;
  }
  
  .json-item {
    display: flex;
    margin-bottom: 10px;
  }
  
  .json-key {
    color: #ff79c6;
    margin-right: 10px;
  }
  
  .json-value {
    color: #8be9fd;
  }
  
  .copy-button, .download-button {
    position: absolute;
    top: 10px;
    background-color: #3e4ee1;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    margin-right: 10px;
  }
  
  .copy-button {
    right: 151px;
}
  
  .download-button {
    right: 10px;
  }
  
  .copy-button:hover, .download-button:hover {
    background-color: #3246b7;
  }
  .json-display {
    font-family: 'Courier New', Courier, monospace;
    background-color: #2b2b2b;
    color: #dcdcdc;
    padding: 20px;
    border-radius: 5px;
    position: relative;
    margin-top: 20px;
}