.checkbox-list {
    font-family: Arial, sans-serif;
  }
  
  .checkbox-list-title {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 0.5em;
    color: #000;
}
  
  .checkbox-list-items {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
  }
  
  .checkbox-list-item {
    margin-bottom: 0.5em;
  }
  
  .checkbox-input {
    margin-right: 0.5em;
  }

  
.checkbox-label {
  font-size: 15px;
  color: #3e4ee1;
  /* font-weight: 600; */
  /* margin-bottom: 6px; */
}
.checkbox-list-items {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-evenly;
}